import React from "react";

//react-bootstrap
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

//mui
import CheckIcon from '@mui/icons-material/Check';
import ToggleButton from '@mui/material/ToggleButton';

import resetArrow from "../resources/arrow-clockwise.svg";
import tuneIcon from "../resources/tune_FILL0_wght400_GRAD0_opsz24.svg";

import "../styles/options.css";

function OptionsDropdown({ showOptions, toggleShow, hasAux, toggleAux, handleReset, negFeasibleRegion, toggleNegFeasibleRegion}){
    const options = [hasAux, negFeasibleRegion];
    
    const settingsTooltip = (
        <Tooltip>
            Options
        </Tooltip>
    )
    
    const tooltip = (
        <Tooltip>
            Reset Tableau
        </Tooltip>
    );
    
    return (
        <div className="container-fluid my-3">
            <div className="row">
                <div className="col-1">
                    <OverlayTrigger placement="top" overlay={settingsTooltip} delay={0}>
                        <button className={`btn ${showOptions ? "btn-primary" : "btn-outline-primary"}`} 
                            onClick={toggleShow}>
                                <img src={tuneIcon} alt="toggle settings dropdown"/>
                        </button>
                    </OverlayTrigger>
                </div>
                {
                    options.map((option) => (
                        <div className="col">
                            <hr style={{"border": `3px solid ${ option ? "green" : "black"}`}}/>
                        </div>
                    ))
                }
                <div className="col-1 p-2">
                <OverlayTrigger placement="top" overlay={tooltip} delay={0}>
                    <button className="btn btn-outline-danger" onClick={handleReset}>
                        <img src={resetArrow} alt="Reset Table"/>
                    </button>
                </OverlayTrigger>
                </div>
            </div>
            <div className={`pt-2 slide-block ${showOptions ? 'open' : 'closed'}`}>
                <div className="row pt-2">
                    <div className="col-1"></div>
                    <div className="col-11">
                        <ToggleOption currentState={hasAux} handleClick={toggleAux} 
                        message="Include Auxiliary Function"/>
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col-1"></div>
                    <div className="col-11">
                        <ToggleOption currentState={negFeasibleRegion} handleClick={toggleNegFeasibleRegion} 
                        message="Plot Negative Feasible Region"/>
                    </div>
                </div>

            </div>
        </div>
    );
}

function ToggleOption({currentState, handleClick, message}){
    return (
        <>
            <ToggleButton
            color="success"
            value="check"
            selected={currentState}
            onChange={handleClick}
            >
                <CheckIcon />
            </ToggleButton>
            <h6 style={{"display":"inline-block", "margin":10}}>{message}</h6>
        </>
    );
}

export default OptionsDropdown;

//make sure react-bootstrap and mui included in packages