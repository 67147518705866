import React from "react";

import addSVG from "../resources/plus-circle-dotted.svg";
import removeSVG from "../resources/x-square.svg";

function Tableau(props) {
    return (
        <div className="row">
            <div className="col" style={{"overflow":"scroll"}}>
                <table className="table table-dark">
                    {
                        //Row Labels
                    }
                    <colgroup>
                        <col span="1"/>
                    </colgroup>

                    {
                        //Data
                    }
                    <colgroup>
                        <col span={props.columns.length}/>
                    </colgroup>

                    {
                        //Consts
                    }
                    <colgroup>
                        <col span="1"/>
                    </colgroup>
                    
                    <TableHeader columns={props.columns} changeColumns={props.changeColumns} 
                    handleAddColumn={props.addCol} handleClick={props.handleClick}
                    hasAux={props.hasAux} varX={props.varX} varY={props.varY}/>
                    
                    <TableBody rows={props.rows} data={props.data} 
                        handleChange={props.handleChange} changeRows={props.changeRows}
                        handleAddRow={props.addRow} handleRemoveColumn={props.removeCol}
                        handleRemoveRow={props.removeRow}
                        consts={props.consts} handleChangeConst={props.handleChangeConst}
                        handleClick={props.handleClick} hasAux={props.hasAux}
                    />

                </table>
            </div>
        </div>
        
    );
}

function TableHeader({ columns, changeColumns, handleAddColumn, handleClick, hasAux, varX, varY}){
    return (
        <thead>
            <tr>
                {
                    columns.map((col,i)=> {
                        return (
                            <th scope="col" key={`header${i}`} className={((col === varX || col === varY) && (col !== "")) && 'border-top-3 border-warning'}>
                                {
                                ((hasAux) ? i<=2 : i<=1) ? 
                                    i === 0 ? <></> :
                                    <DisabledElement key={`headerElement${i}`} value={col}/>
                                    :
                                    <Element 
                                        key = {`headerElement${i}`}
                                        value={col}
                                        row={0} 
                                        col={i}
                                        handleChange={changeColumns}
                                        handleClick={handleClick}
                                    />
                                }
                            </th>
                        )
                    })
                }
                <th>
                    <DisabledElement value="b"/>
                </th>
                <th className="table-success">
                    <AddColumnButton handleAddColumn={handleAddColumn}/>
                </th>
            </tr>
        </thead>
    );
}

function TableBody({ rows, data, handleChange, changeRows, handleAddRow, handleRemoveColumn, 
    handleRemoveRow, consts, handleChangeConst, handleClick, hasAux}){
    return (
        <tbody>
            {
                rows.map((row,i) => {
                    return (
                        <tr key={`row${i}`}>
                            <th scope="row" key={`th${i}`}>
                                {
                                    ((i===0) || (i===1 && hasAux))?
                                        <DisabledElement key={`rowLabel${i}`} value = {row}/>
                                    :
                                        <Element
                                            key={`rowLabel${i}`}
                                            value={row}
                                            row={i}
                                            col={-1}
                                            handleChange={changeRows}
                                            handleClick={handleClick}
                                        />
                                }
                            </th>
                            {
                                data[i].map((value,j) => {
                                    return (
                                        <td key={`tableData${i}${j}`} className="table-secondary">
                                            <Element
                                                key={`matrixData${i}`}
                                                value={value}
                                                row={i}
                                                col={j}
                                                handleChange={handleChange}
                                                handleClick={handleClick}
                                            />
                                        </td>
                                    );
                                })
                            }
                            <td className="table-info">
                                <Element
                                    value={consts[i]}
                                    row={i}
                                    col={null}
                                    handleChange={handleChangeConst}
                                    handleClick={handleClick}
                                />
                            </td>
                            {i > 0 ?
                                <td key={`removeRowTD${i}`} className="table-warning">
                                    <RemoveRowButton rowIndex={i} key={`removeRowImg${i}`}
                                        handleRemoveRow={handleRemoveRow}/>
                                </td>
                                :
                                <td className="table-warning"></td>
                            }
                        </tr>
                    );
                })
            }
            <tr className="table-warning">
                <th className="table-success">
                    <AddRowButton handleAddRow={handleAddRow}/>
                </th>
                {
                    data[0].map((value, index) => {
                        return (
                            <>
                                {(index !== 0) ?
                                    <td key={`removeCol${index}`}>
                                        <RemoveColumnButton key={`removeColBtn${index}`}
                                            handleRemoveColumn={handleRemoveColumn} colIndex={index}
                                        />
                                    </td>
                                    :
                                    <td key={`nullRemove${index}`}></td>
                                }
                            </>
                        );
                    })
                }
                <td></td>
                <td></td>
            </tr>
        </tbody>
    );
}


function Element({ value, row, col, handleChange, handleClick }) {
    return (
        <input
            type="text"
            style={{"width":90}}
            value = {value}
            onChange={e => handleChange(e.target.value, row, col)}
            onClick={e => handleClick(e)}
        />
    )
}

function DisabledElement({ value }) {
    return <input style={{"width":90}} value={value} disabled="disabled"/>;
}

function RemoveColumnButton({ handleRemoveColumn, colIndex}) {
    return (
        <button className="btn btn-danger" onClick={() => handleRemoveColumn(colIndex)}>
            <img src={removeSVG} height={20} alt="Delete Column"/>
        </button>
    );
}

function RemoveRowButton({ rowIndex, handleRemoveRow }){
    return (
        <button className="btn btn-danger" onClick={()=>handleRemoveRow(rowIndex)} key={`removeRowBtn${rowIndex}`}>
            <img src={removeSVG} height={20} alt="Delete Row" />
        </button>
    );
}

function AddColumnButton({ handleAddColumn }) {
    return (
        <button className="btn btn-success" onClick={handleAddColumn}>
            <img src={addSVG} height={20} alt="Add column"/>
        </button>
    );
}

function AddRowButton({ handleAddRow }){
    return (
        <button className="btn btn-success" onClick={handleAddRow}>
            <img src={addSVG} height={20} alt="Add row"/>
        </button>
    );
}

export default Tableau;