import React, {useState, useEffect} from 'react';
import './App.css';
import ProgressButton from './container/ProgressButton';
import HelpBar from './container/HelpBar';
import Tableau from './container/Tableau';
import Checkpoint from './container/Checkpoint';
import "./styles/react-progress-button.css";
import OptionsDropdown from './container/OptionsDropdown';
//react-bootstrap
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DesmosCalc from './container/DesmosCalc';
import VariableSelect from './container/VariableSelect';
import TooSmall from './container/TooSmall';
import logo from "./resources/ss_logo.png";

//heroku server
const baseURL = "https://simplex-backend-server-3bdf0b447717.herokuapp.com/";

function App() {

  const [columns, setColumns] = useState(["", "z"]);
  const [rows, setRows] = useState(["z"]);
  const [matrix, setMatrix] = useState([[1]]);
  const [hasAuxFn, setAuxFunction] = useState(false);
  const [b, setB] = useState([0]);
  const [activeCell, setActiveCell] = useState(null);
  const [oldClass, setOldClass] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  const [showVariableSelect, setShowVariableSelect] = useState(false);
  const [xVar, setXVar] = useState("");
  const [yVar, setYVar] = useState("");
  const [negFeasibleRegion, setNegFeasibleRegion] = useState(true);

  const addRow = () => {
    setRows((prevRows) => [...prevRows, ""]);
    setMatrix((prevMat) => [...prevMat, new Array(prevMat[0].length).fill(0)]);
    setB((prevB) => [...prevB, 0]);
    clearActiveCell(activeCell, oldClass);
  }

  const addCol = () => {
      setColumns((prevCols) => [...prevCols, ""]);
      setMatrix((prevMatrix) => prevMatrix.map(row => [...row, 0]));
      clearActiveCell(activeCell, oldClass);
  }

  const removeRow = (index) => {
      setRows((prevRows)=>[...prevRows.slice(0,index), ...prevRows.slice(index+1)]);
      setMatrix((prevMat)=>[...prevMat.slice(0,index), ...prevMat.slice(index+1)]);
      setB((prevB)=> [...prevB.slice(0,index), ...prevB.slice(index+1)]);
      clearActiveCell(activeCell, oldClass);
  }

  const removeCol = (index) => {
      setColumns((prevCols)=>[...prevCols.slice(0,index+1), ...prevCols.slice(index+2)]);
      setMatrix((prevMat)=>prevMat.map(
          row => [...row.slice(0,index),...row.slice(index+1)]
      ));
      clearActiveCell(activeCell, oldClass);
  }

  const handleChange = (value, row, col) => {
      setMatrix((prevMatrix) => {
          let newMatrix = [...prevMatrix];
          newMatrix[row][col] = value;
          return newMatrix;
      })
  }

  const handleChangeConst = (value, index, col_ignore) => {
      setB((prevB) => {
          let newB = [...prevB];
          newB[index] = value;
          return newB;
      })
  }

  const handleChangeColumns = (newValue, row, col) => {
      setColumns((prevCols) => {
          let newCols = [...prevCols];
          newCols[col] = newValue;
          return newCols;
      })
  }

  const handleChangeRows = (newValue, row, col) => {
      setRows((prevRows) => {
          let newRows = [...prevRows];
          newRows[row] = newValue;
          return newRows;
      })
  }

  const clearActiveCell = (cell, classes) => {
    if (cell !== null) {
      let parent = cell.parentElement;
      if (parent.classList.contains("table-primary")) {
        parent.classList.remove("table-primary")
        classes.forEach(styleClass => {
          parent.classList.add(styleClass);
        });
      }
      setActiveCell(null);
      setOldClass([]);
    }
  }
  //change focus between table cells
  const handleClickElement = (e) => {
    clearActiveCell(activeCell, oldClass);
    let newParent = e.target.parentElement;
    let newClasses = [];
    newParent.classList.forEach(styleClass => {
      newClasses.push(styleClass);
      newParent.classList.remove(styleClass);
    })
    setOldClass(newClasses);
    newParent.classList.add("table-primary");
    setActiveCell(e.target);
  }

  //handle click on page
  const handleClickOnApp= (event) => {
    if (event.target === event.currentTarget){
      clearActiveCell(activeCell, oldClass);
    }
  }

  const [buttonState, setButtonState] = useState("");
  const [populated, setPopulated] = useState(false);
  const [checkpoints, setCheckpoints] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  //wait till checkpoints has been stored to set populated
  useEffect(()=> {
    if (checkpoints !== null) {
      setPopulated(true);
    }
  }, [checkpoints])

  const handleRun = () => {
    let url = new URL("/backend/solve/simplex", baseURL);

    //Set Button to Loading
    setButtonState("loading");

    let data = {
      "columnLabels": columns.slice(1),
      "rowLabels": rows,
      "matrix": matrix,
      "b": b,
      "hasAux": hasAuxFn
    }

    fetch( url, {
      "method": "POST",
      "headers": {"Content-Type": "application/json"},
      "body": JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if ("error" in data) {
          setErrorMsg(data["error"]);
          setPopulated(false);
          setButtonState("error");
        }
        else 
        {
          //success
          storeCheckpoints(data);
          setErrorMsg("");
          setButtonState("success");
        }
      })
      .catch(error => {
          setErrorMsg(error);
          setPopulated(false);
          setButtonState("error");
      })
      //reset button state
      setButtonState("");
  }

  const storeCheckpoints = (data) => {
    let iterations = Object.keys(data);
    iterations.sort();
    let ckp = [];
    let curCkp = null;
    iterations.forEach(iteration => {
      curCkp = data[iteration];
      curCkp.b = JSON.parse(curCkp.b);
      curCkp.matrix = JSON.parse(curCkp.matrix);
      ckp.push(curCkp);
    })
    setCheckpoints(ckp);
  }

  const clearCheckpoints = () => {
    setPopulated(false);
    setCheckpoints(null);
  }

  const toggleAuxFn = () => {
    setAuxFunction((prevState) => !prevState);
  }

  useEffect(() => {
    if (hasAuxFn && !(rows.includes("z'"))) {
      //columns
      let newCols = [...columns.slice(0,2), "z'"];
      if (columns.length > 2){
        newCols = newCols.concat(columns.slice(2));
      }

      //rows
      let newRows = [rows[0], "z'"];
      if (rows.length > 1) {
        newRows = newRows.concat(rows.slice(1));
      }

      //matrix
      let insertRow = new Array(columns.length).fill(0)
      insertRow[1] = 1;
      let teM = [];
      for(var i = 0; i < matrix.length; i++){
        teM.push([matrix[i][0], 0, ...matrix[i].slice(1)]);
      }
      let newMatrix = [teM[0], insertRow, ...teM.slice(1)];

      //b
      let newB = [b[0], 0];
      if (b.length > 1) {
        newB = newB.concat(b.slice(1));
      }
      
      setColumns(newCols);
      setRows(newRows);
      setMatrix(newMatrix);
      setB(newB);

    } else {
      if (!hasAuxFn && rows.includes("z'")) {
        removeRow(1);
        removeCol(1);
      }
    }
    setActiveCell(null);
  }, [hasAuxFn])

  const resetTableau = () => {
    setAuxFunction(false);
    setColumns(["", "z"]);
    setRows(["z"]);
    setMatrix([[1]]);
    setB([0]);
    setXVar("");
    setYVar("");
    clearActiveCell(activeCell, oldClass);
    //Clear Checkpoints
    setPopulated(false);
    setCheckpoints(null);
  }

  /*Called by Test1 Button*/
  const test1 = () => {
    setRows(["z", "x4", "x5"]);
    setColumns(["","z", "x1", "x3", "x4", "x5"]);
    setB([0,0,1]);
    setMatrix([
      [1,0,-0.5,0,0],
      [0,1,-0.5,1,0],
      [0,-0.33, 0.5,0,1]
    ]);
    setAuxFunction(false);
    setXVar("x1");
    setYVar("x3");
    clearCheckpoints();
    clearActiveCell(activeCell, oldClass);
  }

  const test2 = () => {
    setRows(["z", "z'", "x4", "x5", "x6"]);
    setColumns(["","z", "z'", "x1", "x3", "x4", "x5", "x6"]);
    setB([0,-1,0,1,-1]);
    setMatrix([
      [1,0,0,-0.5,0,0,0],
      [0,1,-1,0,0,0,1],
      [0,0,1,-0.5,1,0,0],
      [0,0,-0.33,0.5,0,1,0],
      [0,0,-1,0,0,0,1]
    ]);
    setAuxFunction(true);
    setXVar("x1");
    setYVar("x3");
    clearCheckpoints();
    clearActiveCell(activeCell, oldClass);
  }

  const test3 = () => {
    setRows(["z", "z'", "x3", "x4", "x5", "x6"]);
    setColumns(["","z", "z'", "x1", "x2", "x3", "x4", "x5", "x6"]);
    setB([0,-2,2,2,12,-2]);
    setMatrix([
      [1,0,-1,-3,0,0,0,0],
      [0,1,-1,-1,0,0,0,1],
      [0,0,1,0,1,0,0,0],
      [0,0,0,1,0,1,0,0],
      [0,0,3,4,0,0,1,0],
      [0,0,-1,-1,0,0,0,1]
    ]);
    setAuxFunction(true);
    setXVar("x1");
    setYVar("x2");
    clearCheckpoints();
    clearActiveCell(activeCell, oldClass);
  }

  const toggleShowOptions = () => {
    setShowOptions(prev => !prev);
  }

  //Extra Credit for 444
  const ec = () => {
    setRows(['z', "z'",'x1', 'x2', 'x3', 'x4', 'x5', 'x6', 'x7', 'x8']);
    setColumns(["", "z", "z'", 'Q1', 'Q2', "x1", 'x2', 'x3', 'x4', 'x5', 'x6', 'x7', 'x8']);
    setB([-975, -175 ,640, -85, 240, 420, 120, 75, 120, -90]);
    setMatrix([
      [1,0,0,-2, 0,0,0,0,0,0,0,0],
      [0,1,-1.67,0,  1,0,0,0,0,0,0,1],
      [0,0,1,1, 1,0,0,0,0,0,0,0],
      [0,0,-1,0,  0,1,0,0,0,0,0,0],
      [0,0,1,0, 0,0,1,0,0,0,0,0],
      [0,0,0,1, 0,0,0,1,0,0,0,0],
      [0,0,0.33,0.25, 0,0,0,0,1,0,0,0],
      [0,0,0,0.25, 0,0,0,0,0,1,0,0],
      [0,0,0.25,0.25, 0,0,0,0,0,0,1,0],
      [0,0,-0.67,0, 0,0,0,0,0,0,0,1]
    ]);
    setAuxFunction(true);
    setXVar("Q1");
    setYVar("Q2");
    clearCheckpoints();
    clearActiveCell(activeCell, oldClass);
  }

  const clearTooltip = (
    <Tooltip>
      Clear Checkpoints
    </Tooltip>
  )

  const handleChangeX = (event) => {
    setXVar(event.target.value);
  };

  const handleChangeY = (event) => {
    setYVar(event.target.value);
  }

  const handleClickOpen = () => {
    setShowVariableSelect(true);
  };

  const handleClickClose = () => {
    setShowVariableSelect(false);
  }

  const toggleNegFeasibleRegion = () => {
    setNegFeasibleRegion(prev => !prev);
  }

  return (
      <div className="App" onClick={handleClickOnApp}>
        <div 
          className='container mt-5 pb-5 d-block d-sm-none'
        >
          <TooSmall />
        </div>
        <div className="container mt-3 pb-5 d-none d-sm-block">
          <HelpBar />
          <OptionsDropdown 
              showOptions={showOptions}
              toggleShow={toggleShowOptions}
              hasAux={hasAuxFn} 
              toggleAux={toggleAuxFn}
              handleReset={resetTableau}
              negFeasibleRegion={negFeasibleRegion}
              toggleNegFeasibleRegion={toggleNegFeasibleRegion}
          />
          <VariableSelect
            open={showVariableSelect}
            setOpen={handleClickOpen}
            setClose={handleClickClose}
            varX={xVar}
            setX={handleChangeX}
            varY={yVar}
            setY={handleChangeY}
            variables={columns}
          />
          <Tableau 
            columns={columns} changeColumns={handleChangeColumns} addCol={addCol} removeCol={removeCol}
            rows={rows} changeRows={handleChangeRows} addRow={addRow} removeRow={removeRow}
            consts={b} handleChangeConst={handleChangeConst}
            data={matrix} handleChange={handleChange}
            handleClick={handleClickElement} hasAux={hasAuxFn}
            varX={xVar} varY={yVar}
            />
          
          <ProgressButton
            className="my-3"
            controlled={true} 
            onClick={handleRun} 
            state={buttonState}
          >
            Run
          </ProgressButton>
          {
            errorMsg !== "" && <div className="alert alert-danger"><h3>{errorMsg}</h3></div> 
          }


          {
            (populated) && <Checkpoint data={checkpoints} />
          }

          { 
            (populated) && <DesmosCalc data={checkpoints} matrix={matrix} b={b} 
            xVar={xVar} yVar={yVar} negFeasibleRegion={negFeasibleRegion}/>
          }

          <div className="btn-toolbar justify-content-center" role="toolbar" aria-label="Toolbar with button groups">
            <div className="btn-group mr-3" role="group">
              <button className="btn btn-light" type="button" onClick={test1}>
                Test 1
              </button>
              <button className="btn btn-light" type="button" onClick={test2}>
                Test 2
              </button>
              <button className="btn btn-light" type="button" onClick={test3}>
                Test 3
              </button>
              <button className="btn btn-light" type="button" onClick={ec}>
                EC?
              </button>
            </div>
            <div className="btn-group" role="group">
              <OverlayTrigger placement='right' overlay={clearTooltip} delay={0}>
                <button type="button" className="btn btn-outline-danger close" aria-label="Close" onClick={clearCheckpoints}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default App;
