import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function VariableSelect({ open, setOpen, setClose, varX, setX, varY, setY, variables }) {

  const objectives = ["z", "z'"];
  const options = variables.filter((option) => !objectives.includes(option));

  const handleClickOpen = () => {
    setOpen();
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setClose();
    }
  };

  return (
    <div className='mb-3'>
      <Button startIcon={varX !== "" && varY !== "" ? <CheckCircleOutlineIcon /> : <WarningAmberIcon/> } variant='outlined' size='large' onClick={handleClickOpen} color={`${varX !== "" && varY !== "" ? 'success' : 'error'}`} >Select Variables to Graph</Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>
          Select Variables to Graph
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl sx={{ m: 1, minWidth: 120 }} error={varX === ""}>
              <InputLabel id="demo-dialog-select-label">x</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={varX}
                onChange={setX}
                input={<OutlinedInput label="X" />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {
                    options.map((opt, i) => {
                        return <MenuItem value={opt} key={`xOpt${i}`} disabled={opt === varY}>{opt}</MenuItem>;
                    })
                }
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }} error={varY === ""}>
              <InputLabel id="demo-dialog-select-label">y</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={varY}
                onChange={setY}
                input={<OutlinedInput label="Y" />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {
                    options.map((opt, j) => {
                        return <MenuItem value={opt} key={`yOpt${j}`} disabled={opt === varX}>{opt}</MenuItem>;
                    })
                }
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}