import React, { useState } from "react";
import helpIcon from "../resources/question-circle.svg";
import TitleWriter from "./TitleWriter";
import "../styles/SlideToggle.css";

function HelpBar() {
    const [showHelp, setHelp] = useState(false);
    const toggleHelp = () => {
        setHelp(!showHelp);
    }

    return (
        <div className="row">
            <div className="col-11">
              <TitleWriter />
              <hr/>
              <div className={`slide-block ${showHelp ? 'open' : 'closed'}`}
                    style={{"fontSize":17}}>   
                <ol className="mx-4">
                    <li>
                        In the objective function and each constraint move the constant term to the right hand side.
                        Everything else should be on the left hand side.
                    </li>
                    <li>
                        Convert all inequality constraints to the {"<="} form.
                        <ul>
                            <li>
                                Multiply the {">="} constraints by -1 on both sides.
                            </li>
                            <li>
                                Leave the {"<="} and == constraints untouched.
                            </li>
                            <li>
                                The only exceptions are constraints of the form: x1 {">="} 0,
                                x2 {">="} 0, ...
                            </li>
                        </ul>
                    </li>
                    <li>
                        Add slack variables to all the inequality constraints.
                        <ul>
                            <li>
                                Slack variables should be on the LHS of {"<="} inequality constraints and always
                                with a preceding + sign.
                            </li>
                            <li>
                                No slack variables are needed for equality constraints.
                            </li>
                            <li>
                                <span style={{"textDecoration": "italic"}}>
                                    The LP is now in <strong>restricted normal form</strong>.
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Check if any of the constraints is infeasible at the origin given by the original variables.
                    </li>
                    <li>
                        If any of the original constraints is infeasible at the origin, create an <strong>auxillary objective
                        function</strong> z' from the slack variable equation for that costraint. If more than one constraint
                        is infeasible at the origin, create the objective function z' from the sum of <strong>all</strong>
                        such constraints.
                    </li>
                    <li>
                        Fill in the initial tableau reflecting the final set of slack variable equations. The auxilliary objective
                        function z' is maximized first. Then objective function z is maximized. <strong> When there are 
                        no more negative coefficients in the objective function, the function is maximized.</strong>
                    </li>
                </ol>
                <hr/>
            </div>

            </div>
            <div className="col-1 p-4">
                <button className="btn-primary btn" type="button" onClick={toggleHelp}>
                    <img src={helpIcon} height={30} alt="Toggle Help Bar"/>
                </button>
            </div>
        </div>
    )
}

export default HelpBar;