import React from "react";
import Lottie from 'react-lottie';
import flipAnimation from '../resources/flip';

export default function TooSmall(){
    const animationData = {
        loop: true,
        autoplay: true,
        animationData: flipAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <h6><em>Welcome to Dubsy's Simplex Solver</em></h6>
            <Lottie 
                options={animationData}
                height={300}
                width={300}
            />
            <h4>You Need a Bigger Screen!<br/>
            (Flip your phone)
            </h4>
        </>
    );
}