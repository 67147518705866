import React from 'react';

function Checkpoint({ data }) {
    return (
        <>
            {
                //Iterate through checkpoints
                data.map((iteration, i) => {
                    return (
                        <div className="container mt-4" key={`containerIteration${i}`}> 
                            <h2 className="text-center alert alert-success" key={`header${i}`}>Iteration {i+1}:</h2>
                            <div className="row" key={`rowCkpTableau${i}`}>
                                <CpTableau 
                                checkpoint={iteration}
                                key={`ckptTableau${i}`}
                                />
                            </div>
                            <div className="row justify-content-between" key={`rowSolution${i}`}>
                                <div className="col-5">
                                    <SolutionTable 
                                    checkpoint={iteration}
                                    key={`solutionTable${i}`}
                                    />
                                </div>
                                <div className="col-5">
                                    <CpSummary 
                                        checkpoint={iteration} 
                                        key={`summary${i}`}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </>
    );
}

function CpTableau({ checkpoint }){
    //matrix, colLabels, rowLabels, pivotCol, pivotRow, 
    let pivotColIdx = checkpoint.colLabels.indexOf(checkpoint.pivotCol);
    return (
        <table className="table table-dark">
            <thead>
                <tr>
                    <th scope="col"></th>
                    {
                        checkpoint.colLabels.map((col,i) => {
                            return (
                                <th className={i === pivotColIdx ? "table-primary" : ""} 
                                scope="col" key={`colHeader${i}`}>{col}</th>
                            );
                        })
                    }
                    <th>b</th>
                </tr>
            </thead>
            <tbody>
                {
                    checkpoint.rowLabels.map((row,j) => {
                        return (
                            <tr key={`row${j}`} className={row === checkpoint.pivotCol ? "table-primary" : ""}>
                                <th scope="row" key={`rowHeader${j}`}>{row}</th>
                                {
                                    checkpoint.matrix[j].map((el,k) => {
                                        return (
                                            <td key={`element${j}${k}`} className={k === pivotColIdx ? "table-primary" : ""}>
                                                {Number.isInteger(el) ? el : (Number(el.toFixed(2)) + (el.toFixed(2).slice(3) === "9" ? 0.01 : 0))}
                                            </td>
                                        );
                                    })
                                }
                                <td key={`constEl${j}`}>
                                    {Number.isInteger(checkpoint.b[j]) ? checkpoint.b[j] : (Number(checkpoint.b[j].toFixed(2)) + (checkpoint.b[j].toFixed(2).slice(3) === "9" ? 0.01 : 0))}
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    );
}

function CpSummary({ checkpoint }){
    //colLabels, rowLabels, pivotCol, pivotRow
    let zeroVars = checkpoint.colLabels.filter(label => !(checkpoint.rowLabels.includes(label)));
    let basicVars = checkpoint.rowLabels.filter(label => label !== "z" && label !== "z'");
    return (
        <table style={{border: "one"}}className="table table-sm table-striped">
        {/*<div>
            <h4>
                <span className='font-weight-bold'>Pivot Column:</span> {checkpoint.pivotCol}<br/>
                <span className='font-weight-bold'>Pivot Row:</span> {checkpoint.pivotRow}<br/>
                <span className='font-weight-bold'>Zero Variables:</span> {zeroVars.join(", ")} <br/>
                <span className='font-weight-bold'>Basic Variables:</span> {basicVars.join(", ")}
            </h4>
        </div>*/} 
        <tbody>
            <tr>
                <th scope="row" style={{width: 150}}>Pivot Column</th>
                <td>{checkpoint.pivotCol}</td>
            </tr>
            <tr>
                <th scope="row">Pivot Row</th>
                <td>{checkpoint.pivotRow}</td>
            </tr>
            <tr>
                <th scope="row">Zero Variables</th>
                <td>{zeroVars.join(", ")}</td>
            </tr>
            <tr>
                <th scope="row">Basic Variables</th>
                <td>{basicVars.join(", ")}</td>
            </tr>
        </tbody>
        </table>

    );

}

function SolutionTable( { checkpoint }){
    if (checkpoint.success === 1) {
        let basicVars = checkpoint.rowLabels
        return (
            <table className="table">
                <thead>
                    <tr className="table-active">
                        <th>Variable</th>
                        {
                            checkpoint.colLabels.map((label, idx) => {
                                return (
                                    <th key={`solutionVar${idx}`}>{label}</th>
                                );
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    <th>Value</th>
                        {   
                            checkpoint.colLabels.map((label) => {
                                return (
                                    basicVars.includes(label) ?
                                        <td>{Number.isInteger(checkpoint.solution[label]) 
                                            ? checkpoint.solution[label] 
                                            : (Number(checkpoint.solution[label].toFixed(2)) + (checkpoint.solution[label].toFixed(2).slice(3) === "9" ? 0.01 : 0))
                                            }</td>
                                        :
                                        <td>0</td>
                                );
                            })
                        }
                </tbody>
            </table>
        );
    } else {
        return (
            <p>
                This iteration could not be solved.
            </p>
        );
    }
}

export default Checkpoint;