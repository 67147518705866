import React, { Component } from "react";
import "../styles/calculator.css";
import Desmos from 'desmos';

class DesmosCalc extends Component {
  componentDidMount() {
    const { data, matrix, b, yVar, xVar, negFeasibleRegion } = this.props;
    const hasAux = data[0].rowLabels.includes("z'");
    const x = data[0].colLabels.indexOf(xVar);
    const y = data[0].colLabels.indexOf(yVar);

    const constraintOp = negFeasibleRegion ? '<=' : '>=';

    const adj_factor = hasAux ? 1 : 0;
    const finalCkpt = data[data.length - 1];

    //Optimal Points
    let optPoints = [];
    let id;
    data.forEach((ckpt, i) => {
      id = `point${i}`;
      optPoints.push({
        "id": `${id}`,
        "folderId": "ptFolder",
        "type": "expression",
        "latex": `P_${i}=(${ckpt.solution[ckpt.colLabels[x]]}, ${ckpt.solution[ckpt.colLabels[y]]})`,
        "hidden": false,
        "label": `Iteration ${i + 1}`,
        "showLabel": true,
        
      });
    });

    //Restricted Constraints
    let constraints = [];
    matrix.forEach((row, idx) => {
      if (idx > adj_factor){
        id = `constraint${idx}`;
        constraints.push({
          "id": `${id}`,
          "folderId": "constraintFolder",
          "type": "expression",
          "latex": `${b[idx]} ${constraintOp} ${row[x]}x + ${row[y]}y`
        });
      }
    })

    //normal constraints
    constraints.push({
      "id": "constraintX",
      "folderId": "constraintFolder",
      "type": "expression",
      "latex": `x ${constraintOp} 0`
    });

    constraints.push({
      "id": "constraintY",
      "folderId": "constraintFolder",
      "type": "expression",
      "latex": `y ${constraintOp} 0`
    });

    //Objective Function
    let objFn = [];
    
    objFn.push({
      "id": "objFn",
      "folderId": "objFolder",
      "type": "expression",
      "latex": `z + ${matrix[0][x]}x + ${matrix[0][y]}y = ${b[0]}`
    });

    objFn.push({
      "id": "objFnSlider",
      "folderId": "objFolder",
      "type": "expression",
      "latex": `z = ${finalCkpt.solution["z"]}`
    });


    const initialState = {
      "version":7,
      "graph":{
        "showGrid":true,
        "showXAxis":true,
        "showYAxis":true,
        "xAxisStep":0,
        "yAxisStep":0,
        "xAxisMinorSubdivisions":0,
        "yAxisMinorSubdivisions":0,
        "xAxisArrowMode":"NONE",
        "yAxisArrowMode":"NONE",
        "xAxisLabel":`${xVar}`,
        "yAxisLabel":`${yVar}`,
        "xAxisNumbers":true,
        "yAxisNumbers":true,
        "polarMode":false,
        "polarNumbers":false,
        "degreeMode":false,
        "projectorMode":false,
        "squareAxes":true,
        "viewport":{
          "xmin":-2,
          "ymin":-2,
          "xmax":5,
          "ymax":5
        }
      },
      "expressions":{
        "list":[
          {
            "id": "0",
            "type": "text",
            "text": "Graphical representation of the feasible region and progression of the optimal solution by iteration."
          },
          //Folders
          {
            "id": "ptFolder",
            "type": "folder",
            "title":"Optimal Solutions",
            "hidden":false,
            "collapsed":true,
            "secret":false
          },
          ...optPoints,
          {
            "id":"constraintFolder",
            "type": "folder",
            "title":"Constraints",
            "hidden":false,
            "collapsed":true,
            "secret":false
          },
          ...constraints,
          {
            "id":"objFolder",
            "type": "folder",
            "title":"Objective Function",
            "hidden":false,
            "collapsed":true,
            "secret":false
          },
          ...objFn
        ]
      }
    };

    const elt = document.getElementById('calculator');
    this.calculator = Desmos.GraphingCalculator(elt,{
      "folders": true,
      "sliders": true,
      "notes": true
    });
    this.calculator.setState(initialState);
    this.calculator.setDefaultState(initialState);
  }

  componentWillUnmount() {
    if (this.calculator) {
      this.calculator.destroy();
    };
  }

  render() {
    return (
      <div className='d-flex justify-content-center my-4'>
        <div id="calculator" className="calcStyle text-align-center">  
        </div>
      </div>
    );
  }
}

export default DesmosCalc;