import Typewriter from 'typewriter-effect';

export default function TitleWriter() {
    return (
        <h1 className="display-2">
            <Typewriter
                options={{
                    loop: true,
                }}
                onInit={(tw) => {
                    tw.typeString('Optimization by Simplex Method')
                        .pauseFor(2500)
                        .deleteChars(14)
                        .typeString("Tableau Method")
                        .pauseFor(2500)
                        .deleteChars(17)
                        .pauseFor(300)
                        .typeString("for Linear Programming")
                        .pauseFor(1000)
                        .typeString("?")
                        .pauseFor(400)
                        .deleteChars(1)
                        .pauseFor(100)
                        .typeString("!")
                        .pauseFor(400)
                        .deleteChars(23)
                        .pauseFor(400)
                        .typeString(".")
                        .pauseFor(400)
                        .typeString("..")
                        .pauseFor(400)
                        .deleteAll()
                        .pauseFor(2000)
                        .start()
                }}
            />
        </h1>
    );
}
